@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,500;1,100&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .prevCalendarButton {
    padding: 3px;
    position: absolute;
    top: 20px;
    right: 33px;
    width: 40px;
  }
  .nextCalendarButton {
    padding: 3px;
    position: absolute;
    right: 12px;
    top: 20px;
  }

  .customPlaceholder::placeholder {
    height: 50%;
    overflow: auto;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 35px;
  }

  .customDropdownMenu:hover svg,
  .customDropdownMenu:hover p {
    color: white;
  }

  .customDropdownMenu:focus-within,
  .customDropdownMenu:active,
  .customDropdownMenu:focus-within svg {
    font-weight: bold;
    background-color: #5d75f9;
    color: white;
  }

  .optionCustom {
    font-weight: bold;
    color: white;
    background-color: #5d75f9;
  }
  .optionCustom svg {
    color: white;
  }

  .text-title {
    @apply font-default tracking-tight text-2xl font-default;
  }

  .btn-primary {
    @apply bg-primary rounded-md shadow-sm text-center leading-4 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary px-2 py-3 box-border transition ease-in-out delay-150 hover:shadow-lg duration-300;
  }

  .btn-secondary {
    @apply bg-secondary rounded-md shadow-sm text-center leading-4 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary px-2 py-3 box-border transition ease-in-out delay-150 hover:shadow-lg duration-300;
  }

  .btn-tertiary {
    @apply bg-tertiary rounded-md shadow-sm text-center leading-4 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tertiary px-2 py-3 box-border;
  }

  .btn-error {
    @apply bg-red-500 rounded-md shadow-sm text-center leading-4 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 px-2 py-3 box-border;
  }

  .toast-success {
    @apply bg-green-500 text-white p-4 rounded-md;
  }

  .toast-error {
    @apply bg-red-500 text-white p-4 rounded-md;
  }
}

.DayPicker_weekHeader {
  top: 55px !important;
}

.CalendarDay {
  padding: 0px !important;
}

.CalendarDay__highlighted_calendar {
  background: #5d75f9 !important;
}

html {
  height: 100vh;
  background-color: white;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}
